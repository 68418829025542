.outlet {
  height: 100%;
  padding: 0px;
}

.home-container {
  position: relative;
  height: 100%;
  width: 100%;
  transition: background-image 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.welcome-message-container {
  color: white;
  flex-grow: 1;
  text-align: center;
}

.welcome-message {
  background: rgba(0, 0, 0, .75);
  padding: 0.5em 0.0em;
}

paper-button {
  background: green;
}

h2 {
  margin: 0;
  color: orangered;
}

a {
  color: inherit;
  text-decoration: none;
}

#nextPhoto {
  display: none;
}